// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #f1f1f1;
    padding: 20px 0;
    width: 100%;
    bottom: 0;
    position: fixed;
}

.footer-content {
    text-align: center;
    margin-bottom: 10px;
}

.footer-bottom p {
    margin: 0;
}

.footer-bottom a {
    color: #6c757d;
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
}

.footer-bottom a:hover {
    text-decoration: underline;
}

.footer-bottom span {
    font-weight: bold;
}

.footer-right {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.footer-right a {
    color: #6c757d;
    font-size: 24px;
    text-decoration: none;
}

.footer-right a:hover {
    color: #333;
}

.payment-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.payment-logos svg {
    width: 40px;
    height: 40px;
    fill: currentColor;
}

.payment-logos svg:hover {
    opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,WAAW;IACX,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".footer {\n    background-color: #f1f1f1;\n    padding: 20px 0;\n    width: 100%;\n    bottom: 0;\n    position: fixed;\n}\n\n.footer-content {\n    text-align: center;\n    margin-bottom: 10px;\n}\n\n.footer-bottom p {\n    margin: 0;\n}\n\n.footer-bottom a {\n    color: #6c757d;\n    text-decoration: none;\n    margin-left: 10px;\n    margin-right: 10px;\n}\n\n.footer-bottom a:hover {\n    text-decoration: underline;\n}\n\n.footer-bottom span {\n    font-weight: bold;\n}\n\n.footer-right {\n    position: absolute;\n    right: 20px;\n    bottom: 20px;\n}\n\n.footer-right a {\n    color: #6c757d;\n    font-size: 24px;\n    text-decoration: none;\n}\n\n.footer-right a:hover {\n    color: #333;\n}\n\n.payment-logos {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.payment-logos svg {\n    width: 40px;\n    height: 40px;\n    fill: currentColor;\n}\n\n.payment-logos svg:hover {\n    opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
