// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-menu-icon {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .mobile-menu-icon {
        display: block;
    }

    .left {
        display: none;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        background-color: #f8f9fa;
        padding: 20px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .mobile-menu a {
        margin-bottom: 20px;
        font-size: 18px;
        color: inherit;
        text-decoration: none;
    }
}

.language-switcher {
    border: 1px solid #ccc; 
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
    margin-right: 20px;
}



`, "",{"version":3,"sources":["webpack://./src/css/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,eAAe;AACnB;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,kBAAkB;QAClB,UAAU;QACV,OAAO;QACP,WAAW;QACX,yBAAyB;QACzB,aAAa;QACb,yCAAyC;IAC7C;;IAEA;QACI,mBAAmB;QACnB,eAAe;QACf,cAAc;QACd,qBAAqB;IACzB;AACJ;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".mobile-menu-icon {\n    display: none;\n    font-size: 24px;\n    cursor: pointer;\n}\n\n@media (max-width: 768px) {\n    .mobile-menu-icon {\n        display: block;\n    }\n\n    .left {\n        display: none;\n    }\n\n    .mobile-menu {\n        display: flex;\n        flex-direction: column;\n        position: absolute;\n        top: 100px;\n        left: 0;\n        width: 100%;\n        background-color: #f8f9fa;\n        padding: 20px;\n        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    }\n\n    .mobile-menu a {\n        margin-bottom: 20px;\n        font-size: 18px;\n        color: inherit;\n        text-decoration: none;\n    }\n}\n\n.language-switcher {\n    border: 1px solid #ccc; \n    border-radius: 8px;\n    padding: 5px 10px;\n    font-size: 16px;\n    cursor: pointer;\n    transition: all 0.3s ease;\n    outline: none;\n    margin-right: 20px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
