// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.contact-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.contact-container form {
    display: flex;
    flex-direction: column;
}

.contact-container label {
    margin-bottom: 15px;
}

.contact-container input,
.contact-container textarea {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    margin-top: 5px;
}

.contact-container .error-message {
    color: red;
    font-size: 14px;
}

.contact-container .success-message {
    color: green;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
}

.contact-container button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/css/Contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;;IAEI,aAAa;IACb,eAAe;IACf,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".contact-container {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.contact-container h1 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.contact-container form {\n    display: flex;\n    flex-direction: column;\n}\n\n.contact-container label {\n    margin-bottom: 15px;\n}\n\n.contact-container input,\n.contact-container textarea {\n    padding: 10px;\n    font-size: 16px;\n    width: 100%;\n    margin-top: 5px;\n}\n\n.contact-container .error-message {\n    color: red;\n    font-size: 14px;\n}\n\n.contact-container .success-message {\n    color: green;\n    font-size: 16px;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.contact-container button {\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
